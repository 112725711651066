<template>
	<div class="LubokPageView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateWithFixedSubmenu hSpace hSpace--page maxWidth maxWidth--page"
			 :class="{ 'vSpace vSpace--page' : $root.isSmallDevice }" v-if="post">
			<!-- header + aside (both sticky) -->
			<div>
				<div :class="{ 'sticky vSpace vSpace--page' : !$root.isSmallDevice }">
					<LuPageHeader
						:title="$root.getTranslatedAcfValue(post.acf, 'postTitle')"
						v-if="post"
					></LuPageHeader>

					<LuSubNav
						:contentModules="$root.getContentModules( post )"
						v-if="post && !$root.isSmallDevice"
					></LuSubNav>
				</div>
			</div>
			<!-- spacer -->
			<div>
				<div class="vSpace vSpace--page vSpace--noBottom">
					<div class="height height--pageHeader"></div>
				</div>
			</div>
			<!-- content: contentModules -->
			<div class="hSpace hSpace--gapEnlargeLeft" :class="{ 'vSpace vSpace--pageBottom' : !$root.isSmallDevice }">

				<div class="border border--top border--strong"></div>

				<div
					v-for="(contentModule, index) in $root.getContentModules( post )"
					:key="index"
					v-bind="contentModule"
					:class="contentModuleClasses( contentModule, index )"
					:is="'Lu' + _.upperFirst(contentModule.acf_fc_layout)"
				></div>

				<LuSpacer
					:lines="5"
				></LuSpacer>
			</div>
		</div>

		<LuNotFound v-if="isNotFound"></LuNotFound>

		<!--
		<pre data-name="post">{{post}}</pre>
	 	-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import ViewMixin from './View.mixin.js'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuSubNav from '@/components/LuSubNav.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuHeadline from '@/components/LuHeadline.vue'
	import LuText from '@/components/LuText.vue'
	import LuGallery from '@/components/LuGallery.vue'
	import LuSpacer from '@/components/LuSpacer.vue'
	import LuNotFound from '@/components/LuNotFound.vue'

	export default {
		name: 'LubokPageView',
		components: {
			MhDevGrid,
			MhSmartHeader,
			LuMobileHeader,
			LuSubNav,
			LuPageHeader,
			LuHeadline,
			LuText,
			LuGallery,
			LuSpacer,
			LuNotFound,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				post       : null,
				isNotFound : false,
			}
		},
		watch: {},
		computed: {},
		methods: {
			doFetch() {
				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// fetch event by slug
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						slug : this.$route.params.slug,
					},
					callback : (response) => {
						const data  = response.data
						const posts = data.result

						this.post = posts[0]
						this.viewIsReady = true // this comes from View.mixin.js

						if( this.post ){
							this.title = this.$root.getTranslatedAcfValue( this.post.acf, 'postTitle')
						}else{
							this.isNotFound = true
							this.title = ''
						}

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
			contentModuleClasses( contentModule, index ){
				let classes = []

				if( contentModule.hasAnchor && index > 0 ) {
					//classes.push('border')
					//classes.push('border--top')
					//classes.push('border--strong')
				}

				return classes
			}
		},
		created() {},
		mounted() {
			this.doFetch()
		},
	}
</script>

<style lang="less">
</style>
