<!--

	<LuSubNav
		:contentModules="{}"
	></LuSubNav>

-->

<template>
		<nav class="LuSubNav color contentModule">
			<div class="border border--top border--strong color--award "></div>
			<ul class="LuSubNav__ul color color--award">
				<li class="LuSubNav__li border border--bottom"
					v-for="(contentModule, index) in contentModulesWithAnchors"
					:class="rowClasses( index )"
					:key="index">
					<a	class="LuSubNav__a LuSubNav__a--first font XXXfont--sizeMedium font--bold vSpace vSpace--navRow"
						:href="'#' + $root.slugify( contentModule.anchorTitle )"
						@click="scrollToElm( $root.slugify( contentModule.anchorTitle ), $event)">{{contentModule.anchorTitle}}</a>
				</li>
			</ul>
		</nav>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuSubNav',
		components: {},
		mixins: [],
		props: {
			contentModules: [Array, Boolean],
		},
		data() {
			return {
				debugLog : false,
			}
		},
		watch: {},
		computed: {
			contentModulesWithAnchors(){
				return this._.filter( this.contentModules, 'hasAnchor' )
			}
		},
		methods: {
			rowClasses( index ){
				let classes = []

				if( index == 0 ){
					//classes.push('LuSubNav__li--isActive')
				}
				if( index == this.contentModulesWithAnchors.length-1 ){
					classes.push('border')
					classes.push('border--bottom')
					classes.push('border--strong')
				}

				return classes
			},
			scrollToElm( anchorId, e = false ){
				const selector  = '[id="'+anchorId+'"]'
				const targetElm = document.querySelector(selector)

				if( this.debugLog ){
					console.log('')
					console.group('scrollToElm()')
					console.log('e:', e)
					console.log('selector: ' , selector)
					console.log('targetElm: ' , targetElm)
					console.groupEnd()
				}

				// scroll target into view
				targetElm.scrollIntoView({behavior: "smooth", block: "start"})

				// set target id as hash
				history.pushState(null, null, '#' + anchorId);

				// preventDefault to let the scrolling be smooth
				if( e ) e.preventDefault()
			},
		},
		created() {},
		mounted() {
			const hash = location.hash.substr(1)

			if( this.debugLog ){
				console.log('');
				console.group('LuSubNav mounted()');
				console.log('mounted');
				console.log('hash:', hash);
				console.groupEnd();
			}

			// on load: if there is a hash: scroll to
			if( hash ){
				setTimeout( () => {
					this.scrollToElm( hash, false )
				}, 150 )
			}
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuSubNav {
		position: relative;

		&__row {
			display: flex;
		}
		&__ul {
			list-style: none;
		}

		&__li {
			list-style: none;
			display: flex;
		}
		&__li--isActive {
			&::after {
				position: absolute;
				top: 0.5rem; left: -1rem;
				content: "•";
				font-size: 1.5rem;
			}
		}

		&__a {
			position: relative;
			display: flex;
			flex-grow: 1;
			text-decoration: none;
			align-items: center;
			color: inherit;
			display: block;
		}
		&__a:hover {
			color: black;
		}
	}
</style>
